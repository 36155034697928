<template>
  <div>
    <t-advanced-search v-if="hasAdvancedSearchSlot">
      <slot name="advanced-search"></slot>
    </t-advanced-search>
    <KTPortlet>
      <template v-slot:title>
        <slot name="toolbar" v-if="hasToolbarSlot"></slot>
      </template>
      <template v-slot:body>
        <div class="table-responsive" style="overflow-x: auto;">
          <b-table
            striped
            :fields="fields"
            hover
            :items="listDataProvider"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            style="min-width:1500px;"
          >
            <template v-slot:empty="scope">
              <h4>{{ scope.emptyText }}</h4>
            </template>
            <template
              v-for="(tableName, selfName) in tableScopedSlots"
              v-slot:[tableName]="slotProps"
            >
              <slot :name="selfName" v-bind="slotProps" />
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col md="2">
            <b-form-group
              label="Per page"
              label-cols-sm="3"
              label-cols-md="5"
              label-cols-lg="5"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4" offset-md="6">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index';
import KTPortlet from '@/views/content/Portlet';
export default {
  name: 'generalList',
  props: {
    tableFields: { type: Array, default: null }
  },
  data() {
    return {
      fields: [],
      items: [],
      totalItems: 0,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 20, 30, 50, 100, 200],
      sortBy: null,
      sortDesc: null
    };
  },
  components: { KTPortlet, TAdvancedSearch },
  methods: {
    initFields() {
      this.fields = this.tableFields;
      this.fields.push({ key: 'actions', label: 'Actions' });
    },
    getTableScopedSlots() {
      let tableScoped = {};
      let entries = Object.entries(this.$scopedSlots);
      for (const [k] of entries) {
        if (k.indexOf('table-') === 0) {
          tableScoped[k] = k.substr(6);
        }
      }
      return tableScoped;
    },
    listDataProvider(context, callback) {
      new Promise(resolve => {
        this.$emit('list-data-provider', context, ({ data }) => {
          resolve(data);
        });
      }).then(data => {
        this.totalItems = data.total;
        callback(data.items);
      });
    }
  },
  computed: {
    hasAdvancedSearchSlot() {
      return !!this.$slots['advanced-search'];
    },
    hasToolbarSlot() {
      return !!this.$slots['toolbar'];
    },
    tableScopedSlots() {
      return this.getTableScopedSlots();
    }
  },
  created() {
    this.initFields();
  }
};
</script>
